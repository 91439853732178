import React from "react";
import { navbarStyle, DropDownListButton } from "@aim-mf/styleguide";

class OUBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let user = this.props.currentUser;
        if (
            Object.keys(user).length === 0 ||
            Object.keys(this.props.OU).length === 0
        ) {
            return <div />;
        }

        // console.log(this.props.OU)
        let EntityListData = [user.ou_map[user.assigned_ou[0]]];
        EntityListData = EntityListData.concat(
            Object.keys(this.props.OU.children).map(ou_id => user.ou_map[ou_id])
        );

        let EntityListID = [user.assigned_ou[0]];
        EntityListID = EntityListID.concat(Object.keys(this.props.OU.children));

        return (
            <div
                style={{
                    width: "fit-content",
                    display: "inline-flex"
                }}
            >
                <DropDownListButton
                    DropDownButtonStyle={{ width: navbarStyle.OUDropDownWidth }}
                    value={user.ou_map[user.current_ou]}
                    data={EntityListData}
                    CustomOnChange={event => {
                        let newCurrentOU = user.current_ou;
                        for (let key in user.ou_map) {
                            if (user.ou_map.hasOwnProperty(key)) {
                                if (
                                    user.ou_map[key] === event.value &&
                                    EntityListID.indexOf(key) !== -1
                                )
                                    newCurrentOU = key;
                            }
                        }
                        this.props.handleCurrentOUChange({
                            ou_id: newCurrentOU
                        });
                    }}
                />
            </div>
        );
    }
}

export { OUBlock };
