import React from "react";
import { Navbar } from "./page/navbar";
import {
    fetchAdminAPIWithPermission,
    fetchAPIWithPermission,
    requestToken,
} from "@aim-mf/api";
import { navigateToUrl } from "single-spa";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";



const initialState = {
    OU: {},
    loadingOU: true,
    loadingCurrentUser: true,
    currentUser: {}
};
function App() {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    // console.log(state);
    const { OU, loadingOU, currentUser, loadingCurrentUser } = state;

    React.useEffect(() => {
        //console.log(loadingCurrentUser)
        if (loadingCurrentUser) {
            dispatch({ type: "loadCurrentUser" });
            dispatch({
                type: "loadCurrentUserInfo",
                data: {
                    ...JSON.parse(sessionStorage.getItem("CurrentUser")),
                    ...JSON.parse(sessionStorage.getItem("MyProfile"))
                }
            });
        }

        if (loadingOU) {
            loadOU();
        }
    }, [loadingCurrentUser, loadingOU]);

    const loadOU = React.useCallback(() => {
        const OUSubscription = fetchAdminAPIWithPermission("auth/ou/info", {
            data: {
                ou_id: JSON.parse(sessionStorage.getItem("CurrentUser"))
                    .assigned_ou[0]
            }
        }).subscribe(
            results => {
                // console.log(results);
                dispatch({ type: "loadOU", results });
            },
            err => {
                AuthCheckAndAutofix(err, () => {
                    loadOU();
                });
            }
        );
        return () => OUSubscription.unsubscribe();
    });

    const handleLogOut = React.useCallback(() => {
        navigateToUrl("/login/");

        const subscription = fetchAPIWithPermission("unlockrisk").subscribe(
            results => {
                console.log("unlocked risk "); // eslint-disable-line
                fetchAdminAPIWithPermission("auth/logout").subscribe(
                    results => {
                        sessionStorage.removeItem("CurrentUser");
                        sessionStorage.removeItem("MyProfile");
                        sessionStorage.removeItem("jwt");
                        sessionStorage.removeItem("nav");
                        sessionStorage.removeItem("riskList");
                        sessionStorage.removeItem("type_object_list");
                        sessionStorage.removeItem("alerted_risk");
                        sessionStorage.removeItem("alerted_object_id");
                        sessionStorage.removeItem("alert_type");
                    },
                    err => {
                        AuthCheckAndAutofix(err, () => {
                            handleLogOut();
                        });
                    }
                );
            },
            err => {
                console.log("err", err); // eslint-disable-line
                AuthCheckAndAutofix(err, () => {
                    handleLogOut();
                });
            }
        );

        return () => subscription.unsubscribe();
    });

    const handleCurrentOUChange = React.useCallback(data => {
        let current_user = JSON.parse(sessionStorage.getItem("CurrentUser"));
        current_user.current_ou = data.ou_id;
        sessionStorage.setItem("CurrentUser", JSON.stringify(current_user));
        dispatch({
            type: "loadCurrentUserInfo",
            data: {
                ...JSON.parse(sessionStorage.getItem("CurrentUser")),
                ...JSON.parse(sessionStorage.getItem("MyProfile"))
            }
        });
        requestToken({ou:current_user.current_ou, tenant:current_user.tenant}, ()=>{window.location.reload(false);})
    });

    return (
        <div>
            <Navbar
                OU={OU}
                currentUser={currentUser}
                handleLogOut={handleLogOut}
                handleCurrentOUChange={handleCurrentOUChange}
            />
        </div>
    );
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case "loadCurrentUser":
            return {
                ...state,
                loadingCurrentUser: true
            };
        case "loadingOU":
            return {
                ...state,
                loadingOU: true
            };
        case "notLoadingOU":
            return {
                ...state,
                loadingOU: false
            };

        case "loadOU":
            return {
                ...state,
                OU: action.results.data,
                loadingOU: false
            };

        case "loadCurrentUserInfo":
            return {
                ...state,
                currentUser: action.data,
                loadingCurrentUser: false
            };

        default:
            throw Error(`Unknown action type '${action.type}'`);
    }
}

export default App;
